.table tbody tr td img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 2px;
}

.table tbody tr td  i{
    cursor: pointer;
}

.table tbody tr td {
    color: var(--primary-color);
}

.lari-img img{
    width: 20px;
}

.subtotal__section{
    border-radius: 3px;
    box-shadow: 2px 1px 28px 1px rgba(123, 11, 11, 0.52);
    -webkit-box-shadow: 0px 0px 6px -2px var(--primary-color);
    -moz-box-shadow: 6px 4px 18px -2px rgba(123, 11, 11, 0.52);
    padding: 10px;
}