.product__item-red {
  cursor: pointer;
  border-radius: 5px;
  width: 220px;
  box-shadow: 1px 0px 12px -4px rgba(189, 3, 3, 0.88);
  -webkit-box-shadow: 0px 1px 11px -3px rgba(189, 3, 3, 0.88);
  -moz-box-shadow: 1px 0px 12px -4px rgba(189, 3, 3, 0.88);
  background: #fff;
  margin-bottom: 20px;
}

.product__item-white {
  cursor: pointer;
  border-radius: 5px;
  width: 220px;
  box-shadow: 1px 0px 12px -4px rgba(189, 3, 3, 0.88);
  -webkit-box-shadow: 0px 1px 11px -3px rgba(68, 189, 3, 0.88);
  -moz-box-shadow: 1px 0px 12px -4px rgba(189, 3, 3, 0.88);
  background: #fff;
  margin-bottom: 20px;
}

.product__item-rose {
  cursor: pointer;
  border-radius: 5px;
  width: 220px;
  box-shadow: 1px 0px 12px -4px rgba(189, 3, 3, 0.88);
  -webkit-box-shadow: 0px 1px 11px -3px rgba(251, 0, 197, 0.88);
  -moz-box-shadow: 1px 0px 12px -4px rgba(189, 3, 3, 0.88);
  background: #fff;
  margin-bottom: 20px;
}

.product__info h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 5px;
}

.product__info h3 a {
  color: black;
}

.product__card-bottom .price img {
  width: 17px;
}

.product__img img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 220px;
  height: 280px;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid var(--card-bg);
}

.product__card-bottom {
  display: flex;
}

.product__card-bottom span i {
  font-size: 1.2rem;
  padding: 5px;
  background: var(--primary-color);
  color: #fff;
  /* cursor: pointer; */
  border-radius: 50px;
}

.info {
  height: 130px;
  position: relative;
}


.info .product__card-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 992px) {
  .product__info h3 a {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 768px) {
  .product__info h3 a {
    font-size: 0.9rem;
  }

  .product__info span {
    font-size: 0.9rem;
  }

  .product__img img {
    width: 100%;
  }

  .product__card-bottom span i {
    font-size: 1rem;
  }

  .product__item-red {
    width: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .product__item-red {
    width: 80%;
    display: flex;
    margin: auto;
  }

  .product__item-white {
    width: 80%;
    display: flex;
    margin: auto;
  }

  .product__img img {
    width: 190px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px;
  }
  .info {
    width: 100%;
    margin: auto;
  }

  .product__info h3 a {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .product__info span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .product__card-bottom {
    display: flex;
    width: 100%;
    display: inline-block;
    margin: -30px 0;
  }
 
}
@media only screen and (max-width: 415px){
  .product__item-red {
    width: 250px;
    flex-direction: column;
    display: flex;
    margin: auto;
  }
  .product__item-white {
    width: 250px;
    flex-direction: column;
    display: flex;
    margin: auto;
  }

  .product__img img {
    width: 100%;
    height: 400px;
  }
  .product__card-bottom {
    margin: 0;
  }
}

@media only screen and (max-width: 271px){
  .product__item-red {
    width: 200px;
    flex-direction: column;
    display: flex;
    margin: auto;
  }
  .product__item-white {
    width: 200px;
    flex-direction: column;
    display: flex;
    margin: auto;
  }

  .product__img img {
    width: 100%;
    height: 400px;
  }
 
}
@media only screen and (max-width: 231px){
  .product__item-red {
    width: 150px;
    flex-direction: column;
    display: flex;
    margin: auto;
  }
  .product__item-white {
    width: 150px;
    flex-direction: column;
    display: flex;
    margin: auto;
  }

  .product__img img {
    width: 100%;
    height: 400px;
  }
}