.search__section{
    padding: 10px 0;
}

.filter__widget select {
  padding: 8px 20px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: #fff;
  margin: 10px 0;
}

.filter__widget select:focus {
  outline: none !important;
}

.filter__widget select option {
  font-size: 0.9rem;
}


.search__box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 12px;
  margin: 10px 0;
}

.search__box input {
  width: 100%;
  padding: 8px 10px;
  border: none;
  outline: none;
  color: var(--primary-color);
  letter-spacing: 1px;
}

.search__box span i{
    color: var(--primary-color);
}

.product__section{
    background-color: var(--card-bg);
}

@media only screen and (max-width:768px){
  .filter__widget select{
    padding: 7px 20px;
    font-size: .9rem;
  }
}